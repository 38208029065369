import {default as Liveswitch} from 'fm.liveswitch';
import {iRemoteStreamSubscriber} from './iRemoteStreamSubscriber';
import { RemoteStreamController } from './RemoteStreamController';
import { MessageBus } from '../utilities/MessageBus';

export class RemoteScreenShareController {
    

    private connection : Liveswitch.SfuDownstreamConnection;

    private remoteMedia : Liveswitch.RemoteMedia;
    private videoStream :  Liveswitch.VideoStream;

    public htmlVideoElement : HTMLVideoElement;

    constructor(private channel : Liveswitch.Channel, private screenshareChannelName : string){
        channel.addOnRemoteUpstreamConnectionOpen(this.onRemoteConnection);
    }


    private onRemoteConnection = (remoteConnectionInfo : Liveswitch.ConnectionInfo) =>{

        this.remoteMedia = new Liveswitch.RemoteMedia();
        this.videoStream = new Liveswitch.VideoStream(this.remoteMedia);
        this.connection = this.channel.createSfuDownstreamConnection(remoteConnectionInfo, null, this.videoStream); 

        this.connection.open().then(()=>{

            this.htmlVideoElement = this.remoteMedia.getView().getElementsByTagName("Video")[0] as HTMLVideoElement;
            this.connection.addOnStateChange( (c : Liveswitch.ManagedConnection)=>{this.onConnectionStateChange(c)});

            MessageBus.Raise("StartScreenShare", this);
           
        }).fail((ex)=>{
            console.log(ex);            
        }); 
        
    }

    
    onConnectionStateChange(c : Liveswitch.ManagedConnection){
        if (c.getState() == Liveswitch.ConnectionState.Closing || c.getState() == Liveswitch.ConnectionState.Failing) {
            console.log("Screenshare Connection lost");

            console.log(this.screenshareChannelName);

            MessageBus.Raise("ScreenshareStreamStopped", {screenshareChannelName:this.screenshareChannelName});

            //TODO TODO 
            //raise event of screen share
           
        }
    }


    

 

   




}