import {default as Liveswitch} from 'fm.liveswitch';
import { timingSafeEqual } from 'crypto';
import { iLocalMediaDeviceSubscriber } from './iLocalMediaDeviceSubscriber';
import { MessageBus } from '../utilities/MessageBus';

export class ScreenshareStreamController{

    public localMedia : Liveswitch.LocalMedia | null;
    public htmlVideoElement : HTMLVideoElement;
    public useScreenShare : boolean;

    private isStarting : boolean = false;  
    private isStarted : boolean = false;  

    private connection : Liveswitch.SfuUpstreamConnection;

    private videoStream : Liveswitch.VideoStream;


    constructor(){

    }


    public start() : Liveswitch.Future<ScreenshareStreamController>{     
        
        let promise = new Liveswitch.Promise<ScreenshareStreamController>(); 
      
        if(this.localMedia != null){
            this.stop().then(()=>{
                this.doStart().then(()=>{
                    promise.resolve(this);
                }).fail((ex)=>{
                    promise.reject(ex);
                });
            }).fail((ex)=>{
                promise.reject(ex);
            });
        } else {
            this.doStart().then(()=>{
                promise.resolve(this);
            }).fail((ex)=>{
                promise.reject(ex);
            });
        }

        return promise;
        
    }

    
    public stop() : Liveswitch.Future<boolean>{        
        
        let promise = new Liveswitch.Promise<boolean>();       
        
        if(this.isStarting){
          
            //we can't stop while a start is in progress            
            promise.reject(new Liveswitch.Exception("Unable to stop media while it is starting"));

        } else {
                      
            if(this.localMedia){
            
                this.localMedia.stop().then(()=>{         
               
                    this.isStarted = false;
                    
                    this.localMedia?.destroy();
                    promise.resolve(true);                    
    
                }).fail((ex)=>{
                  
                    console.log("Failed to stop local media");
                    console.log(ex);
                    promise.reject(ex);
                });
            }
            
        }

        
        return promise;
    }
    

    private doStart() : Liveswitch.Future<ScreenshareStreamController>{     
           
        let promise = new Liveswitch.Promise<ScreenshareStreamController>();

        if(!this.isStarted){
           
            this.localMedia = new Liveswitch.LocalMedia(true, true, true);           
            
            this.localMedia.addOnVideoStopped(() => {
                console.log("Screenshare stopped");
                MessageBus.Raise("ScreenshareStreamStopped", {screenshareChannelName:""});

                this.connection.close();
                
            });
            
            this.isStarting = true;
            this.localMedia.start().then((_localMedia) => {
               
                this.isStarting = false;
                this.isStarted = true;
                this.htmlVideoElement = this.localMedia?.getView().getElementsByTagName("Video")[0] as HTMLVideoElement;                  
    
                promise.resolve(this);            
            }).fail(function(ex) {
               
                console.log(ex.message);
                promise.reject(ex);
            });     
        } else {
            promise.reject(new Liveswitch.Exception("Local media is already started"));     
        }
        
        return promise;
        
    }

    public connect (channel : Liveswitch.Channel) : Liveswitch.Future<boolean>{
      
        let promise = new Liveswitch.Promise<boolean>();       

        this.videoStream = new Liveswitch.VideoStream(this.localMedia);        

        this.connection = channel.createSfuUpstreamConnection(this.videoStream);

        this.connection.open().then((result) => {
            console.log("SFU Screen Share Upstream connection established");
            promise.resolve(true);
        }).fail(function(ex) {
            console.log("An error occurred setting SFU Up Stream");
            console.log(ex);
            promise.reject(ex);
        });
        

        return promise;
    }


    public disconnect () : Liveswitch.Future<boolean>{
        let promise = new Liveswitch.Promise<boolean>();


        return promise;
    }


}