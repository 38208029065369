import {default as Liveswitch} from 'fm.liveswitch';
import { LocalMediaController } from './LocalMediaController';


export class LocalStreamUpController{

    private audioStream : Liveswitch.AudioStream;
    private videoStream : Liveswitch.VideoStream;

    private connection : Liveswitch.SfuUpstreamConnection;
    private localMediaController : LocalMediaController

    constructor( private client:Liveswitch.Client, private channel : Liveswitch.Channel){

    }
    
    public connect (localMediaController : LocalMediaController, useAudio:boolean, useVideo:boolean) : Liveswitch.Future<boolean>{

        this.localMediaController = localMediaController;

        let promise = new Liveswitch.Promise<boolean>();     

        //Safety check to ensure we actual want to and can use audio / video
        if(!this.localMediaController.isMicrophoneInUse() ||  !this.localMediaController.getLocalMedia() || !this.localMediaController.getLocalMedia().getAudioInput() || !this.localMediaController.getLocalMedia().getAudioInput().getName()){
            console.log("Audio Selection Mismatch");
            useAudio = false;
        }

        if(!this.localMediaController.isCameraInUse() ||  !this.localMediaController.getLocalMedia() || !this.localMediaController.getLocalMedia().getVideoInput() || !this.localMediaController.getLocalMedia().getVideoInput().getName()){
                        
            
            console.log("Video Selection Mismatch")
            useVideo = false;
        }


        if(useAudio){            
            if(this.localMediaController.getLocalMedia() != null){
                this.audioStream = new Liveswitch.AudioStream(this.localMediaController.getLocalMedia());
            }
        }

        if(useVideo){
            if(this.localMediaController.getLocalMedia() != null){
                this.videoStream = new Liveswitch.VideoStream(this.localMediaController.getLocalMedia());

                this.videoStream.setSimulcastMode(Liveswitch.SimulcastMode.RtpStreamId);

                console.log("tracks");            
                console.log(this.videoStream.getLocalMedia().getVideoTracks());

                
            }
        }
        
        let doConnect = true;
        if(useAudio && useVideo){
            this.connection = this.channel.createSfuUpstreamConnection(this.audioStream, this.videoStream);
        } else if(useAudio){
            this.connection = this.channel.createSfuUpstreamConnection(this.audioStream);
        } else if(useVideo){
            this.connection = this.channel.createSfuUpstreamConnection(this.videoStream);
        } else {
            //We don't need to stream up. Our camera and mic is off
            console.log("No media stream. No connection necessary");
            doConnect = false;
            promise.resolve(true);
        }            

        if(doConnect){
            this.connection.open().then((result) => {
                console.log("SFU Upstream connection established");
                promise.resolve(true);
            }).fail(function(ex) {
                console.log("An error occurred setting SFU Up Stream");
                console.log(ex);
                promise.reject(ex);
            });
        }


        
        return promise;
    }

    public disconnect () : Liveswitch.Future<boolean>{
        let promise = new Liveswitch.Promise<boolean>();


        return promise;
    }


    public setVideoMuted(b : boolean){

    }


    public setAudioMuted(b : boolean){

    }

}