import * as BABYLON from 'babylonjs';
import * as BABYLONGUI from 'babylonjs-gui';
import {MessageBus} from '../utilities/MessageBus';
import { AvatarData } from '../avatars/AvatarData';
import { threadId } from 'worker_threads';
import 'babylonjs-loaders';
import { PBRMaterial } from 'babylonjs';
import { PBRCustomMaterial } from 'babylonjs-materials';
import AvatarColor from '../components/Intake/AvatarColor';

export class AvatarConfigureScene{
   
    private avatarRoot : BABYLON.TransformNode;

    private videoTexture : BABYLON.VideoTexture;
    private videoMat : PBRMaterial;
 
    public avatarData : AvatarData = new AvatarData();

    //Instance Pieces
    private glow : BABYLON.InstancedMesh;
    private body : BABYLON.InstancedMesh;
    private bodyGlow : BABYLON.InstancedMesh;
    private head : BABYLON.InstancedMesh;
    private eyes : BABYLON.InstancedMesh;
    private hair : BABYLON.InstancedMesh;
    private mouth : BABYLON.InstancedMesh;
    private webcamScreen : BABYLON.Mesh;
    private nameText : BABYLONGUI.TextBlock;
    private titleText : BABYLONGUI.TextBlock;

    constructor(private userID:string, private scene : BABYLON.Scene, private camera : BABYLON.Camera, canvas : HTMLCanvasElement){       
        camera.detachControl(canvas);
        
        this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 1);

        this.avatarData.userID = this.userID;

        this.subscribeMessages();

        //this.createAvatar();   
    }
    
    
    destroy(){

        this.unsubscribeMessages();

        //TODO TODO TODO
        //tear down all the scene pieces if needed        
    } 

    public onRoomCodeChanged = (s:string) => {
        this.avatarData.roomID = s;
    }

    public onFirstNameChanged = (s : string) => {
        this.avatarData.firstName = s.trim();
        this.UpdateNameText(this.avatarData.firstName, this.avatarData.lastName);

    }

    public onLastNameChanged = (s : string) => {
        this.avatarData.lastName = s.trim();
        this.UpdateNameText(this.avatarData.firstName, this.avatarData.lastName);
    }

    private UpdateNameText(first : string, last: string)
    {
        if(this.nameText){
            this.nameText.text = first.trim() + " " + last.trim();
            this.nameText.fontSize = Math.min(120 / this.nameText.text.length * 15, 120);
        }
    }

    public onCompanyChanged = (s : string) => {
        this.avatarData.company = s.trim();
        
        if(this.titleText){
            this.titleText.text = s.trim();
            this.titleText.fontSize = Math.min(120 / this.titleText.text.length * 15, 80);
        }
    }

    public onColorChanged = (hex : string) => {
        this.avatarData.color = hex;

        let regularColor = BABYLON.Color3.FromHexString(hex).toLinearSpace();
        let highValueColor = regularColor.add(new BABYLON.Color3(0.5, 0.5, 0.5));

        if(this.glow) this.glow.instancedBuffers.color = regularColor;
        if(this.body) this.body.instancedBuffers.color = regularColor;
        if(this.head) this.head.instancedBuffers.color = regularColor;

        if(this.bodyGlow) this.bodyGlow.instancedBuffers.color = highValueColor;
        if(this.eyes) this.eyes.instancedBuffers.color = highValueColor;
        if(this.hair) this.hair.instancedBuffers.color = highValueColor;
        if(this.mouth) this.mouth.instancedBuffers.color = highValueColor;

        if(this.nameText) this.nameText.color = this.titleText.color = hex;
    }

    public onFaceChanged = (i : number) => {

        this.avatarData.mouth = i;
        this.avatarData.eyes = i;

        let uvc = new BABYLON.Vector2(0,0);

        switch(i)
        {
            case 0:
                uvc = new BABYLON.Vector2(0,0);
                break;
            case 1:
                uvc = new BABYLON.Vector2(1/4, 0);
                break;
            case 2:
                uvc = new BABYLON.Vector2(2/4, 0);
                break;
            case 3:
                uvc = new BABYLON.Vector2(3/4, 0);
                break;
            case 4:
                uvc = new BABYLON.Vector2(0, 1/4);
                break;
            case 5:
                uvc = new BABYLON.Vector2(1/4, 1/4);
                break;
            case 6:
                uvc = new BABYLON.Vector2(2/4, 1/4);
                break;
            case 7:
                uvc = new BABYLON.Vector2(3/4, 1/4);
                break;
            default:
                uvc = new BABYLON.Vector2(0, 0);
                break;
        }

        if(this.eyes) this.eyes.instancedBuffers.uvc = uvc;
        if(this.mouth) this.mouth.instancedBuffers.uvc = uvc;
        if(this.hair) this.hair.instancedBuffers.uvc = uvc;
    }

    private loadVideoTexture = (htmlVideoElement : HTMLVideoElement) => {
        this.videoTexture = new BABYLON.VideoTexture("videoTexture", htmlVideoElement, this.scene, false, true);
        this.videoMat.albedoTexture = this.videoTexture;
        if(this.webcamScreen) this.webcamScreen.setEnabled(true);
        if(this.eyes) this.eyes.setEnabled(false);
        if(this.hair) this.hair.setEnabled(false);
        if(this.mouth) this.mouth.setEnabled(false);
    }

    public onUseCameraChanged = (data : any) => {

        let useCamera : boolean = data.useCamera;
        let webcamHTMLVideoElement  : HTMLVideoElement = data.htmlVideoElement;

        this.avatarData.cameraEnabled = useCamera;

        if(useCamera && webcamHTMLVideoElement){            
            this.loadVideoTexture(webcamHTMLVideoElement);             
        } else {
            if(this.webcamScreen) this.webcamScreen.setEnabled(false);
            if(this.eyes) this.eyes.setEnabled(true);
            if(this.hair) this.hair.setEnabled(true);
            if(this.mouth) this.mouth.setEnabled(true);
        }
    }    

    public onUseAudioChanged = (micEnabled : boolean) =>{
        this.avatarData.micEnabled = micEnabled;
    }

    /***********************************************************************
     * 
     * 
     * Private Helpers
     * 
     * 
     ***********************************************************************/

    avatarMeshTask : BABYLON.MeshAssetTask;
    avatarTextureTask : BABYLON.TextureAssetTask;

    public createAvatar = () : Promise<boolean> =>{

        return new Promise((resolve,reject)=>{
            let assetsManager = new BABYLON.AssetsManager(this.scene);

            this.avatarMeshTask = assetsManager.addMeshTask("MainGalleryTask", "", "./assets/models/", "iPadAvatar6-1.glb");
            this.avatarTextureTask = assetsManager.addTextureTask("feetImageTask", "./assets/textures/iPadAvatarFaces5-28.png", false, false);

            assetsManager.onFinish = (tasks) => {
                let avatarTexture = this.avatarTextureTask.texture; //new BABYLON.Texture("/assets/textures/avatarTexture.png", this.scene, false, false);
                avatarTexture.hasAlpha = true;

                //Deconstruct Imported Model            
                let avatarTemplateRoot = new BABYLON.Mesh("avatarTemplateRoot", this.scene);
                let glowTemplate = new BABYLON.Mesh("glowTemplate", this.scene);
                let bodyTemplate = new BABYLON.Mesh("bodyTemplate", this.scene);
                let bodyGlowTemplate = new BABYLON.Mesh("bodyGlowTemplate", this.scene);
                let headTemplate = new BABYLON.Mesh("headTemplate", this.scene);
                let eyeTemplate = new BABYLON.Mesh("eyeTemplate", this.scene);
                let hairTemplate = new BABYLON.Mesh("hairTemplate", this.scene);
                let mouthTemplate = new BABYLON.Mesh("mouthTemplate", this.scene);
                let webCamScreenTemplate = new BABYLON.Mesh("webCamScreenTemplate", this.scene);
                
                
                let meshes = this.avatarMeshTask.loadedMeshes;
                for(let i = 0; i < meshes.length; i++) {
                    if(meshes[i].name == "__root__") {avatarTemplateRoot = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name == "HoverBody") {bodyTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name == "HoverBodyEmit") {bodyGlowTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name == "ScreenFrame") {headTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name == "AvatarFace_Eyes") {eyeTemplate = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name == "AvatarFace_Hair") {hairTemplate = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name == "AvatarFace_Mouth") {mouthTemplate = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name == "Glow") {glowTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name == "WebCamScreen") {webCamScreenTemplate = meshes[i] as BABYLON.Mesh;} 
                }

                let textAreaTemplate = BABYLON.MeshBuilder.CreatePlane("textArea", {width: 1.9, height: 1.9, sideOrientation: 2}, this.scene);
                textAreaTemplate.parent = bodyTemplate;
                textAreaTemplate.position = new BABYLON.Vector3(0, 1.15, 0);

                headTemplate.scaling.x = -1;

                eyeTemplate.scaling =
                hairTemplate.scaling =
                mouthTemplate.scaling = 
                new BABYLON.Vector3(1,1,1);      

                eyeTemplate.rotation =
                hairTemplate.rotation =
                mouthTemplate.rotation = 
                new BABYLON.Vector3(0,0,0);
            
                
                let avatarScale = new BABYLON.Vector3(.55, .55, .55);
                avatarTemplateRoot.scaling = avatarScale;
                avatarTemplateRoot.rotationQuaternion = null;
                avatarTemplateRoot.rotation.y = BABYLON.Tools.ToDegrees(0);
                avatarTemplateRoot.setEnabled(false);
                
                //Create body and head material
                let bodyHeadMat = new BABYLON.PBRMaterial("bodyHeadMat", this.scene);
                bodyHeadMat.transparencyMode = 0;
                bodyHeadMat.albedoTexture = avatarTexture;
                bodyHeadMat.unlit = true;

                bodyTemplate.material =
                headTemplate.material =
                bodyHeadMat;
                
                
                //Create UV adjustable unlit face material
                let uvAdjustableMat = new PBRCustomMaterial("uvAdjustableMat", this.scene);
                uvAdjustableMat.transparencyMode = 0;
                uvAdjustableMat.unlit = true;
                uvAdjustableMat.albedoTexture = avatarTexture;

                uvAdjustableMat.AddAttribute("uvc");
                uvAdjustableMat.Vertex_Definitions("attribute vec2 uvc;");
                uvAdjustableMat.Vertex_Before_PositionUpdated("uvUpdated += uvc;");

                eyeTemplate.material =
                hairTemplate.material =
                mouthTemplate.material =
                bodyGlowTemplate.material =
                uvAdjustableMat;
                
                //Create color adjustable floor glow material
                let colorAdjustableGlowMat = new BABYLON.PBRMaterial("colorAdjustableGlowMat", this.scene);
                colorAdjustableGlowMat.transparencyMode = 2;
                colorAdjustableGlowMat.unlit = true;
                colorAdjustableGlowMat.albedoTexture = avatarTexture;
                colorAdjustableGlowMat.useAlphaFromAlbedoTexture = true;
                
                glowTemplate.material = colorAdjustableGlowMat;

                //Create webcam Material;
                const webcamMaterial = new BABYLON.PBRMaterial("webcamMaterial", this.scene);
                webcamMaterial.unlit = true;
                webCamScreenTemplate.material = webcamMaterial;
                this.videoMat = webcamMaterial;

                webCamScreenTemplate.setEnabled(false);

                //Set Instance buffers
                bodyTemplate.registerInstancedBuffer("color", 4);
                bodyGlowTemplate.registerInstancedBuffer("color", 4);
                headTemplate.registerInstancedBuffer("color", 4);
                eyeTemplate.registerInstancedBuffer("color", 4);
                hairTemplate.registerInstancedBuffer("color", 4);
                mouthTemplate.registerInstancedBuffer("color", 4);
                glowTemplate.registerInstancedBuffer("color", 4);

                bodyTemplate.instancedBuffers.color =
                bodyGlowTemplate.instancedBuffers.color =
                headTemplate.instancedBuffers.color =
                eyeTemplate.instancedBuffers.color =
                hairTemplate.instancedBuffers.color =
                mouthTemplate.instancedBuffers.color =
                glowTemplate.instancedBuffers.color =
                new BABYLON.Color3(0.9, 0.9, 0.9);

                eyeTemplate.registerInstancedBuffer("uvc", 2);
                hairTemplate.registerInstancedBuffer("uvc", 2);
                mouthTemplate.registerInstancedBuffer("uvc", 2);

                eyeTemplate.instancedBuffers.uvc =
                hairTemplate.instancedBuffers.uvc =
                mouthTemplate.instancedBuffers.uvc = 
                new BABYLON.Vector2(0,0);


                //Create Instance
                let newAvatarRoot = new BABYLON.TransformNode("Avatar", this.scene);

                let newAvatarScalingNode = new BABYLON.TransformNode("Avatar_Scale");
                newAvatarScalingNode.parent = newAvatarRoot;
                newAvatarScalingNode.position = new BABYLON.Vector3(0,0,0);
                newAvatarScalingNode.scaling = new BABYLON.Vector3(.55 * 1.5, .55 * 1.5, .55 * 1.5);

                this.glow = glowTemplate.createInstance("Floor Glow");
                this.glow.parent = newAvatarScalingNode;
                this.glow.scaling = new BABYLON.Vector3(.5, .5, .5);

                this.body = bodyTemplate.createInstance("Body");
                this.body.parent = newAvatarScalingNode;

                this.bodyGlow = bodyGlowTemplate.createInstance("Body Glow");
                this.bodyGlow.parent = this.body;

                this.head = headTemplate.createInstance("Head");
                this.head.parent = this.body;

                this.eyes = eyeTemplate.createInstance("Eyes");
                this.eyes.parent = this.head;

                this.hair = hairTemplate.createInstance("Hair");
                this.hair.parent = this.head;

                this.mouth = mouthTemplate.createInstance("Mouth");
                this.mouth.parent = this.head;

                this.webcamScreen = webCamScreenTemplate.clone("Webcam Screen");
                this.webcamScreen.parent = this.head;
                this.webcamScreen.rotation.y = BABYLON.Tools.ToRadians(180);
                this.webcamScreen.setEnabled(false);

                let newTextArea = textAreaTemplate.clone("Text Area");
                newTextArea.parent = this.body;
                newTextArea.rotation.y = BABYLON.Tools.ToRadians(180);
                newTextArea.setEnabled(true);

                //Setup text area
                let avatarText = BABYLONGUI.AdvancedDynamicTexture.CreateForMesh(newTextArea);

                let textStack = new BABYLONGUI.StackPanel();
                avatarText.addControl(textStack);

                this.nameText = new BABYLONGUI.TextBlock();
                this.nameText.height = "150px";
                textStack.addControl(this.nameText);
                this.onFirstNameChanged("");

                this.titleText = new BABYLONGUI.TextBlock();
                this.titleText.height = "150px";
                textStack.addControl(this.titleText);
                this.onCompanyChanged("");


                this.onColorChanged(AvatarColor.avatarColors[1]);

                //Finalize avatar
                newAvatarRoot.setAbsolutePosition(new BABYLON.Vector3(0,.7,0));

                this.avatarRoot = newAvatarRoot;
                
                var n = 0;
                this.scene.onBeforeRenderObservable.add(()=>{
                    n += 0.1;
                    this.body.position.y += Math.cos(n * .5) / 300;
                });    
                
                resolve(true);
            }

            assetsManager.load();
        });
    }


     private subscribeMessages(){
        MessageBus.AddListener("onFirstNameChanged", this.onFirstNameChanged);
        MessageBus.AddListener("onLastNameChanged", this.onLastNameChanged);
        MessageBus.AddListener("onCompanyChanged", this.onCompanyChanged);
        MessageBus.AddListener("onColorChanged", this.onColorChanged);
        MessageBus.AddListener("onFaceChanged", this.onFaceChanged);
        MessageBus.AddListener("onUseCameraChanged", this.onUseCameraChanged);
        MessageBus.AddListener("onUseAudioChanged", this.onUseAudioChanged);
        MessageBus.AddListener("onRoomCodeChanged",this.onRoomCodeChanged);
     }

     private unsubscribeMessages(){
        MessageBus.RemoveListener("onFirstNameChanged", this.onFirstNameChanged);
        MessageBus.RemoveListener("onLastNameChanged", this.onLastNameChanged);
        MessageBus.RemoveListener("onCompanyChanged", this.onCompanyChanged);
        MessageBus.RemoveListener("onColorChanged", this.onColorChanged);
        MessageBus.RemoveListener("onFaceChanged", this.onFaceChanged);
        MessageBus.RemoveListener("onUseCameraChanged", this.onUseCameraChanged);
        MessageBus.AddListener("onUseAudioChanged", this.onUseAudioChanged);
        MessageBus.RemoveListener("onRoomCodeChanged",this.onRoomCodeChanged);
     }
}