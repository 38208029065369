
import * as BABYLON from 'babylonjs';
import * as io from 'socket.io-client';
import {AvatarData} from '../avatars/AvatarData';
import {LobbyDefaultConfig} from '../utilities/LobbyConfig';

export interface SocketIOSubscriber {    
    
    onPlayerConnected : () => void;

    onRemotePlayerConnected : (playerData : AvatarData) => void;
    onRemotePlayerDisconnected : (playerID : AvatarData) => void;
    onMessage : (messageName : string, message : any) => void;
    onGlobalMessage : (messageName : string, message : any) => void;

    onPlayerPositionsUpdate : (playerData : any) => void;
    onPlayerDataUpdate : (playerData: AvatarData) => void;

    onVariableUpdate : (variableName: string, newValue: any) => void;    
}

const ENDPOINT: string  = "wss://" + LobbyDefaultConfig.Server + ":" + LobbyDefaultConfig.Port;    

export default class SocketIOController {
    
    playerID : string;
    playerData : AvatarData;
    listeners : SocketIOSubscriber[] = [];
    socket: SocketIOClient.Socket;


    /*****************************************************************************************************************************  
     * 
     * Constructor
     *       
     *****************************************************************************************************************************/


    constructor(playerID : string, listener : SocketIOSubscriber | undefined){
        this.playerID = playerID;

        if(listener != undefined){
            this.AddListener(listener);
        }

    }

    /*****************************************************************************************************************************  
     * 
     * PUBLIC API
     *       
     *****************************************************************************************************************************/


    public AddListener(listener : SocketIOSubscriber){
        this.listeners.push(listener);
    }


    public RemoveListener(listener : SocketIOSubscriber){
        let index = this.listeners.findIndex( (e) => {return listener == e;});
        if(index >= 0){
            this.listeners.splice(index, 1);
        }
    }


    public Connect(playerData : AvatarData){
        
        this.playerData = playerData;

        // Connect to the server
        this.socket = io.connect(ENDPOINT, {transports: ['websocket']});

        // Reaction to receiving list of existing player's data on connection
        this.socket.on('existing-players-data', (data: any) => {
            // Set existing player's data to AvatarData
            let playerDataArray: AvatarData[] = data.existingPlayers; 
            // Imitate new players adding a number of times
            for(let playerData of playerDataArray){
                this.RemotePlayerConnected(playerData);
            }
        });

        // Reaction to remote player connecting
        this.socket.on('new-player-data', (data: any) =>{
            this.RemotePlayerConnected(data.newPlayerData);
        });
      
        // Reaction to receiving list of existing variables on the server
        this.socket.on('existing-variables-data', (data:any) => {
            let existingVariableData: any[] = data.existingVariableData;
            //Imitate receiving data updates
            for(let i = 0; i < existingVariableData.length; i++){
                let currVarData: any = existingVariableData[i];
                this.RemoteVariableUpdated(currVarData.variableName, currVarData.value);
            }
        }); 
      
        // Reaction to variable update on the server
        this.socket.on('down-variable-update', (data: any) => {
            this.RemoteVariableUpdated(data.variableName, data.value);
        });
      
        // Reaction to a remote player updating their data
        this.socket.on('update-player-data', (data: any) => {
            this.RemotePlayerDataUpdated(data);
        });
        
        // Reaction to remote player disconnecting
        this.socket.on('remote-player-disconnect', (data: any) => {
            this.RemotePlayerDisconnected(data.disconnectedPlayerData);
        });

        // Reaction to receiving other player's positions, as an array
        /*this.socket.on('transform-broadcast', (data: any) => {
            this.PlayerPositionsUpdated(data.transformDataArray);
        });*/

        // Reaction to receiving requested player transform data
        this.socket.on('down-transform-data', (data: any) => {
            this.PlayerPositionsUpdated(data.transformObject);
        }); 

        // Receiving a global message from the server
        this.socket.on('global-broadcast', (data: any) => {
            this.GlobalMessageReceived(data.messageName, data.message);
        });

        // Receiving a targeted message 
        this.socket.on('targeted-message', (data: any) => {
            this.MessageReceived(data.messageName, data.message);
        });

        // Reaction to connecting to the server
        this.socket.on('connect', () => {

            console.log("CONNECT CONNECT");

            // Send player's data to the server
            this.socket.emit('player-data-received', {
                playerData
            });
            
            // Call On Connected Listeners
            this.listeners.forEach((listener)=>{
                listener.onPlayerConnected();
            });
        });


        this.socket.on('disconnect', () => {

            console.log("DISCONNECT DISCONNECT DISCONNECT");
          
        });

    }


    public Disconnect(){
        // Do the actual disconnect, sends a disconnect message to server automatically
        this.socket.disconnect();
    }

    // Send a message to all players
    public SendAll(messageName : string, message : any){
        this.socket.emit('send-all', {
            messageName, 
            message
        });
    }

    
    // Send a message to specific group of players
    public Send(messageName : string, playerIDs : string[], message : any){
        this.socket.emit('send-targeted', {
            messageName, 
            message,
            playerIDs            
        });
    }

    // Request other player's data stored on the server
    public RequestAllData(){
        this.socket.emit('request-room-data');
    }

    // Request just player's transform data
    public RequestTransformData(){
        this.socket.emit('up-transform-data');
    }

    // Send updated player data to server
    public UpdatePlayerData(playerData: AvatarData){
        this.socket.emit('player-data-update-received', {
            playerData
        });
    }

    // Send a variable update to the server
    public SetServerVariable(variableName: string, value: any){
        this.socket.emit('up-variable-update', {
            variableName, 
            value
        });
    }

    // Get a variable potentially saved on the server
    public GetServerVariable(variableName: string, defaultValue: any){
        this.socket.emit('up-request-variable', {
            variableName, 
            defaultValue
        });
    }

    // Tell the server that he player wants to change room
    public ChangeRoom(newRoomID: string){
        this.socket.emit('up-change-room', {
            newRoomID       
        });
    }

    // Send transform data to server
    public UpdatePosition(clientPosition : BABYLON.Vector3, clientRotation : BABYLON.Vector3, storeInAvatarData: Boolean){       
        this.socket.emit('transform-update', {
            position: [clientPosition.x, clientPosition.y, clientPosition.z],
            rotation: [clientRotation.x, clientRotation.y, clientRotation.z], 
            storeInAvatarData: storeInAvatarData
        });
    }

    /*****************************************************************************************************************************  
     * 
     * PRIVATE HELPERS
     *       
     *****************************************************************************************************************************/

    private RemotePlayerConnected(playerData : AvatarData){
        //Called internally when a new player joins
        this.listeners.forEach((listener)=>{
            listener.onRemotePlayerConnected(playerData);
        });
    }

    private RemotePlayerDataUpdated(playerData: AvatarData){
        // Called internally when a player updates their data
        this.listeners.forEach((listener) => {
            listener.onPlayerDataUpdate(playerData);
        });
    }

    private RemotePlayerDisconnected(playerID : AvatarData){
        //Called internally when a player leaves
        this.listeners.forEach((listener)=>{
            listener.onRemotePlayerDisconnected(playerID);
        });
    }

    private RemoteVariableUpdated(variableName: string, value: any){
        // Called internally when a new "VariableUpdated" message is received
        this.listeners.forEach((listener) => {
            listener.onVariableUpdate(variableName, value);
        });
    }

    private MessageReceived(messageName : string, message : any){
        //Called internally when a new "Send" message is recevied
        this.listeners.forEach((listener)=>{
            listener.onMessage(messageName, message);
        });
    }

    private GlobalMessageReceived(messageName : string, message : any){
         //Called internally when a new "SendAll" message is recevied
        this.listeners.forEach((listener)=>{
            listener.onGlobalMessage(messageName, message);
        });
    }

    private PlayerPositionsUpdated(data : any){
         //Called internally when an update of all player positions arrives
        this.listeners.forEach((listener)=>{
            listener.onPlayerPositionsUpdate(data);
        });
    }

}
