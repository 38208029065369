import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import dat from 'dat.gui';

export class NavMeshHandler {
    navMeshDebug : BABYLON.Mesh;

    constructor(private scene : BABYLON.Scene){}

    public GetNavData(navigationPlugin : BABYLON.RecastJSPlugin, meshesToCalculate : BABYLON.Mesh[], fileName : string) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=>{
        //Load saved nav mesh
        this.LoadNavMeshFromFile(navigationPlugin, fileName).then(() => {resolve();});

        //Generate new nav mesh
        /*
        let parameters = {
            cs: 0.2,
            ch: 0.125,
            walkableSlopeAngle: 35,
            walkableHeight: 2.5,
            walkableClimb: 1,
            walkableRadius: 2, //8 for vizio
            maxEdgeLen: 12,
            maxSimplificationError: 1.3,
            minRegionArea: 36, //31 for neon and GE
            mergeRegionArea: 20,
            maxVertsPerPoly: 6,
            detailSampleDist: 6,
            detailSampleMaxError: 1,
        };
        if(meshesToCalculate.length > 0) this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
        resolve();
        */
        });
    }

    private LoadNavMeshFromFile(navigationPlugin : BABYLON.RecastJSPlugin, fileName : string) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=>{
            fetch("navMesh/" + fileName)
            .then(
                (result : Response) => {

                    result.arrayBuffer().then((arrayBufferResult:ArrayBuffer)=>{
                        var uint8View = new Uint8Array(arrayBufferResult);
                        navigationPlugin.buildFromNavmeshData(uint8View);
                        resolve();                  
                    });
                },(error) => {
                console.log(error);
                resolve();
                }
            )
        });
    }

    private CreateNewNavMesh(navigationPlugin : BABYLON.RecastJSPlugin, meshesToCalculate : BABYLON.Mesh[], parameters : BABYLON.INavMeshParameters, saveToFile : boolean){
        navigationPlugin.createNavMesh(meshesToCalculate, parameters);
        //console.log(navigationPlugin.getNavmeshData());

        this.scene.removeMesh(this.navMeshDebug);
        this.navMeshDebug = navigationPlugin.createDebugNavMesh(this.scene);
        var matdebug = new BABYLON.PBRMaterial('matdebug', this.scene);
        matdebug.albedoColor = new BABYLON.Color3(.1, .2, 1);
        matdebug.alpha = .2;
        matdebug.unlit = true;
        this.navMeshDebug.material = matdebug;
        this.navMeshDebug.isPickable = false;

        var oldgui = document.querySelector("#datGUI");
        if (oldgui != null)
        {
            oldgui.remove();
        }

        var gui = new dat.GUI;
        gui.domElement.style.marginTop = "100px";
        gui.domElement.id = "datGUI";
    
        gui.add(parameters, 'cs', 0.01, 1.0).onChange((value) => {
            parameters.cs = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'ch', 0.01, 1.0).onChange((value) => {
            parameters.ch = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'walkableSlopeAngle', 0.0, 90.0).onChange((value) => {
            parameters.walkableSlopeAngle = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });        
        gui.add(parameters, 'walkableHeight', 0.01, 10.0).onChange((value) => {
            parameters.walkableHeight = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });    
        gui.add(parameters, 'walkableClimb', 0.01, 10.0).onChange((value) => {
            parameters.walkableClimb = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'walkableRadius', 0.01, 10.0).onChange((value) => {
            parameters.walkableRadius = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'maxEdgeLen', 0.1, 100.0).onChange((value) => {
            parameters.maxEdgeLen = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'maxSimplificationError', 0.01, 100.0).onChange((value) => {
            parameters.maxSimplificationError = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'minRegionArea', 0.01, 100.0).onChange((value) => {
            parameters.minRegionArea = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'mergeRegionArea', 0.01, 100.0).onChange((value) => {
            parameters.mergeRegionArea = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'maxVertsPerPoly', 3, 16.0).onChange((value) => {
            parameters.maxVertsPerPoly = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'detailSampleDist', 0.9, 20.0).onChange((value) => {
            parameters.detailSampleDist = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });
        gui.add(parameters, 'detailSampleMaxError', 0.01, 10.0).onChange((value) => {
            parameters.detailSampleMaxError = value;
            this.CreateNewNavMesh(navigationPlugin, meshesToCalculate, parameters, false);
            });

        if(saveToFile){
        let downloadURL = function(data, fileName) {
            var a;
            a = document.createElement('a');
            a.href = data;
            a.download = fileName;
            document.body.appendChild(a);
            a.style = 'display: none';
            a.click();
            a.remove();
          };

        let downloadBlob = function(data, fileName, mimeType) {
            var blob, url;
            blob = new Blob([data], {
              type: mimeType
            });
            url = window.URL.createObjectURL(blob);
            downloadURL(url, fileName);
            setTimeout(function() {
              return window.URL.revokeObjectURL(url);
            }, 1000);
          };

          downloadBlob(navigationPlugin.getNavmeshData(), "navMeshData.txt", 'application/octet-stream');
        }
    }
}