import * as React from 'react';
import { TextField, Button } from '@material-ui/core';
import ReactGA from 'react-ga';
import {MessageBus} from '../../utilities/MessageBus';
import {LobbyDefaultConfig} from '../../utilities/LobbyConfig';
import { RoomCodeData } from '../../utilities/RoomCodeData';

import styles from './RoomCode.module.css';

interface Props {
    onRoomCodeComplete : (roomCode:String) => void;
}

interface State {        
    error : boolean,
    errorMsg : string
};

interface RoomCodeStruct{
    code : string,
    opens : string
    expires : string,
    errorMsg : string,
    slides : Array<[string, string]>,
    textures : string[],
    space : string
};



export default class RoomCode extends React.Component<Props, State> {
    
    roomCodeInput : any;


    state : State = {    
       error : false,
       errorMsg : ""
    };
    
    
    componentDidMount() {
        
        ReactGA.pageview('/roomCode');

        /* fetch("config/roomCodes.json")
          .then(res => res.json())
          .then(
            (result) => {
                result.roomCodes.forEach((rc : RoomCodeStruct)=>{
                    this.roomCodeMap.set(rc.code, rc);
                });

            },(error) => {
              this.setState({
                isLoaded: true,
                error
              });
              
             console.log(error);
            }
          ) */
      }
     

    onButtonClick = (evt : React.MouseEvent | null) => {        
        
        if(!this.roomCodeInput.value || this.roomCodeInput.value.length < 1) return;
        
        let roomCodeData = RoomCodeData.RoomCodeMap.get(this.roomCodeInput.value);        

        if(LobbyDefaultConfig.DevelopmentMode){
            this.props.onRoomCodeComplete(this.roomCodeInput.value);
            return;
        }
      
        if(roomCodeData){         
            
            //Check the expiration
            let now = new Date();

            let openTime = new Date(roomCodeData.opens + ":00.000-05:00");
            let expireTime = new Date(roomCodeData.expires + ":00.000-05:00");

            console.log(now);
            console.log(openTime);
            console.log(expireTime);

            if(now < openTime){

                //Too early               
                let updatedState = {
                    error : true,
                    errorMsg : "This room is not yet available"
                }
                this.setState(updatedState);

            } else if(now > expireTime){

                //Too late
               
                let updatedState = {
                    error : true,
                    errorMsg : roomCodeData.errorMsg
                }
                this.setState(updatedState);
                
            } else {           
                
                //Good                
                this.props.onRoomCodeComplete(this.roomCodeInput.value);
               
            }

        } else {
           
            let updatedState = {
                error : true,
                errorMsg : "Invalid Room Code"
            }
            this.setState(updatedState);
        }

        if(evt){
            evt.preventDefault();
        }

    }

    onRoomCodeChange = (event : any) => {
       
    }

    submitHandler = (e) => {
        console.log("BLOCK SUBMIT");
        e.preventDefault();
        this.onButtonClick(null);
    }
   

    render() {

        let errorMsg = this.state.errorMsg;

        return (
            
            <div className={styles.roomCodeIntake}>
                <h1 className={styles.roomCodeHeader}>Please Enter Your Room Code</h1>

                {this.state.errorMsg && 
                    <div className={styles.roomCodeErrorMessageHolder}>
                        <p>{errorMsg}</p>
                    </div>
                }  

                <form className={styles.roomCodeForm} noValidate autoComplete="off" onSubmit={this.submitHandler}>

                    <TextField inputRef={el => this.roomCodeInput = el} className={styles.roomCodeInput} onChange={this.onRoomCodeChange} id="standard-basic" label="Room Code" fullWidth /* error={this.state.error} *//>
                    
                    <Button type="button" className="biodata" onClick={this.onButtonClick} variant="contained" color="primary" style={{ marginTop: '20px'}} >
                        NEXT
                    </Button>
                </form>
            </div>
            
        )
    }

}












