import * as BABYLON from 'babylonjs';
import SocketIOController from '../scene/SocketIOController';
import {SocketIOSubscriber} from '../scene/SocketIOController';
import { LiveSwitchController } from '../liveSwitch/LiveSwitchController';
import { LocalAvatarController } from './LocalAvatarController';
import { iRemoteStreamSubscriber } from '../liveSwitch/iRemoteStreamSubscriber';
import { RemoteStreamController } from '../liveSwitch/RemoteStreamController';
import { RemoteAvatar } from './RemoteAvatar';
import { AvatarData } from '../avatars/AvatarData';
import { RemoteAvatarInstanceFactory } from './RemoteAvatarInstanceFactory';
import { RemoteAvatarView } from './RemoteAvatarView';
import { MessageBus } from '../utilities/MessageBus';



interface iSocketPositionData{
    position : BABYLON.Vector3;
    rotation : BABYLON.Vector3;
    socketid : string;
}


export class RemoteAvatarController implements SocketIOSubscriber, iRemoteStreamSubscriber {

    private avatarInstanceFactory : RemoteAvatarInstanceFactory;

    private remotePlayerMap : Map<string, RemoteAvatar> =  new Map();
    private remotePlayerStreamMap : Map<string, RemoteStreamController> = new Map();

    private factoryReady : boolean = false;
    private remoteConnectionQueue : AvatarData[] = [];

    private elapsedTimeSinceUpdate : number = 0;
    private transformUpdateFrequency = 67;

    constructor (private engine:BABYLON.Engine, private scene:BABYLON.Scene, private camera : BABYLON.FreeCamera, private socketIOController : SocketIOController, private liveswitchController : LiveSwitchController, private localAvatarController : LocalAvatarController, private userID:string){
        
        //Subscrive listerns
        this.socketIOController.AddListener(this);
        

    }

    public load = () => {
        this.avatarInstanceFactory = new RemoteAvatarInstanceFactory(this.scene);
        this.avatarInstanceFactory.CreateTemplate().then(()=>{
            this.factoryReady = true;

            this.remoteConnectionQueue.forEach((avatarData : AvatarData)=>{
                this.onRemotePlayerConnected(avatarData);
            })

            //Tell the socket its ok to start sending us stuff
            /*
            this.socketIOController.RequestOtherPlayerData()
            .then((data:any)=>{
                
                console.log("DATA:");
                console.log(data);

                if(data &&  data.existingPlayers){
                    data.existingPlayers.forEach( (remoteAvatarData)=>{
                        this.onRemotePlayerConnected(remoteAvatarData);
                    });
                }
            }); 
            */
           this.socketIOController.RequestTransformData();

            
            this.scene.registerBeforeRender(() => {
                this.elapsedTimeSinceUpdate += this.engine.getDeltaTime();
                if(this.elapsedTimeSinceUpdate >= this.transformUpdateFrequency){
                    this.elapsedTimeSinceUpdate = 0;
                    this.socketIOController.RequestTransformData();                    
                } 
                
            });

            this.scene.onKeyboardObservable.add((kbInfo) => {
                                
                switch(kbInfo.type){
                    case BABYLON.KeyboardEventTypes.KEYDOWN:
                        
    
    
                        if(kbInfo.event.key == "n"){
                            this.remotePlayerMap.forEach( (_remoteAvatar : RemoteAvatar) => {
                                _remoteAvatar.localMediaController.goMaxResolution();                
                            });
                        }

                        if(kbInfo.event.key == "m"){
                            this.remotePlayerMap.forEach( (_remoteAvatar : RemoteAvatar) => {
                                _remoteAvatar.localMediaController.goMinResolution();                
                            });
                        }
    
                    break;    
                }
            });
            
            


        });
    }

    public subscribeLiveswitchController = () => {
        this.liveswitchController.remoteLobbyController.AddListener(this);
    }
    
    

    /*****************************************************************************
     * 
     * <<SocketIOSubscriber>>
     * 
     ******************************************************************************/

    onPlayerConnected = () => {
        console.log("On Player Connected")

    }

   
    onRemotePlayerConnected = (avatarData: AvatarData) => {     


        console.log("onRemotePlayerConnected");
        console.log(avatarData);

        //Check that this is not ourself
        if(avatarData.userID == this.userID) return;

        

        if(!this.factoryReady){
            this.remoteConnectionQueue.push(avatarData);
            return;
        }
        

        if(!this.remotePlayerMap.has(avatarData.userID)){

            console.log("Adding to map: " + avatarData);

            let avatarInstance : RemoteAvatarView = this.avatarInstanceFactory.GetInstance(this.scene, avatarData.userID);
            

            let remoteAvatar : RemoteAvatar = new RemoteAvatar(this.engine, this.scene, this.camera, avatarData, this.localAvatarController, avatarInstance);
            this.remotePlayerMap.set(avatarData.userID, remoteAvatar);

            
            //Set the media controller if it has already arrived
            let remoteStreamController = this.remotePlayerStreamMap.get(avatarData.userID);
            if(remoteStreamController){
                remoteAvatar.setRemoteMediaController(remoteStreamController);
            }    


            let updatedAttendeeList : AvatarData[] = [];
            this.remotePlayerMap.forEach( (_remoteAvatar : RemoteAvatar) => {
                updatedAttendeeList.push( _remoteAvatar.remoteAvatarData);                  
            });

            MessageBus.Raise("OnAttendeeListChange", updatedAttendeeList);


        } else {
            console.log("Already have userID: " + avatarData.userID);

            //Update the remote avatar with the new data... likely a new socketID after they experienced connection issues
            let remoteAvatar = this.remotePlayerMap.get(avatarData.userID);
            if(remoteAvatar){
                remoteAvatar.remoteAvatarData = avatarData;
            }
            
        }
    }

    onPlayerDataUpdate = (playerData: AvatarData) => {
        //check mic and camera and act accordinly
    };

    onRemotePlayerDisconnected = (playerData: AvatarData) => {

        console.log("onRemotePlayerDisconnected");
        console.log(playerData);

        let remoteAvatar = this.remotePlayerMap.get(playerData.userID) ;

        //Make sure this is the same user ID on the same socket
        if(remoteAvatar && playerData.socketID == remoteAvatar.remoteAvatarData.socketID){
            remoteAvatar.dispose();
            this.remotePlayerMap.delete(playerData.userID);

            let remotePlayerStream = this.remotePlayerStreamMap.get(playerData.userID);
            if(remotePlayerStream){
                remotePlayerStream.stopAudio();
                this.remotePlayerStreamMap.get(playerData.userID);
            }

            let updatedAttendeeList : AvatarData[] = [];
            this.remotePlayerMap.forEach( (_remoteAvatar : RemoteAvatar) => {
                updatedAttendeeList.push( _remoteAvatar.remoteAvatarData);                  
            });

            MessageBus.Raise("OnAttendeeListChange", updatedAttendeeList);

            //TODO TODO TODO //anything else with the media stream?~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        }

        
    };

    onMessage = (messageName: string, message: any) => {

    }

    onGlobalMessage = (messageName: string, message: any) => {

    }

    onVariableUpdate = (variableName: string, newValue: any) => {     
    }
    

    onPlayerPositionsUpdate = (playerData: any) => {

       


        //console.log('onPlayerPositionsUpdate');
        //console.log(playerData);

        if(playerData === undefined ) return;

        
        for(let index in playerData){
            let element = playerData[index];
            let userID = element.userID;
            if(userID != this.userID){
                let remoteAvatar = this.remotePlayerMap.get(userID);

                if(remoteAvatar){
                    
                    let positionArray = element.position;
                    let rotationArray = element.rotation;
                    
                    remoteAvatar.setPosition( positionArray[0], positionArray[1], positionArray[2]);
                    remoteAvatar.setRotation( rotationArray[0], rotationArray[1], rotationArray[2]);
                } else {
                    console.log(this.remotePlayerMap);
                    console.log("Unknown playerID: " + userID);
                }
            } 
        }        

        //Original server
        /*playerData.forEach((element : any) => {
            let userID = element.userID;
            if(userID != this.userID){
                let remoteAvatar = this.remotePlayerMap.get(userID);

                if(remoteAvatar){
                    
                    let positionArray = element.position;
                    let rotationArray = element.rotation;
                    
                    remoteAvatar.setPosition( positionArray[0], positionArray[1], positionArray[2]);
                    remoteAvatar.setRotation( rotationArray[0], rotationArray[1], rotationArray[2]);
                } else {
                    console.log(this.remotePlayerMap);
                    console.log("Unknown playerID: " + userID);
                }
            } 
        });*/
    }


    /*****************************************************************************
     * 
     * <<iRemoteStreamSubscriber>>
     * 
     ******************************************************************************/

     
    onRemoteStreamConnected = (remoteStreamController: RemoteStreamController) : void => {                

        console.log("onRemoteStreamConnected");
        console.log(remoteStreamController);

        //real code
        this.remotePlayerStreamMap.set(remoteStreamController.userID, remoteStreamController);

        //attach it to the avatar if avatar has already joined game
        let remoteAvatar = this.remotePlayerMap.get(remoteStreamController.userID);
        if(remoteAvatar){
            remoteAvatar.setRemoteMediaController(remoteStreamController);
        }
    }

    onRemoteStreamDisconnected = (remoteStreamController: RemoteStreamController): void => {
        let remoteAvatar = this.remotePlayerMap.get(remoteStreamController.userID);
        if(remoteAvatar){
            remoteAvatar.removeRemoteMediaController();
        }
    }



//maintains collection of all avatars in the game
}