import {default as Liveswitch} from 'fm.liveswitch';
import {iRemoteStreamSubscriber} from './iRemoteStreamSubscriber';
import { RemoteStreamController } from './RemoteStreamController';

export class RemoteLobbyController{

    
    private connections : Map<string, RemoteStreamController> = new Map();    
    private listeners : iRemoteStreamSubscriber[] = [];

    constructor(private channel : Liveswitch.Channel){
        channel.addOnRemoteUpstreamConnectionOpen(this.onRemoteConnection);
    }


    public AddListener(listener : iRemoteStreamSubscriber){
        this.listeners.push(listener);
    }


    public RemoveListener(listener : iRemoteStreamSubscriber){
        let index = this.listeners.findIndex( (el) => {return listener == el;});
        if(index >= 0){
            this.listeners.splice(index, 1);
        }
    }


    private onRemoteConnection = (remoteConnectionInfo : Liveswitch.ConnectionInfo) =>{

        let userID = remoteConnectionInfo.getUserId();

        console.log("RemoteLobbyController onRemoteConnection: " + userID);        

        if(this.connections.has(userID)){
            //this should not happen. We should only have each user once
            console.warn("New remote connection for existin user id: " + userID);            
        }


        //Create the remote stream to the new connection
        let remoteStreamController : RemoteStreamController = new RemoteStreamController(this.channel, remoteConnectionInfo);   //todo todo todo //todo
                                                                                                                                //this should not be blindly let in
                                                                                                                                //they need to first be ok'd by the node server
        remoteStreamController.connect(this.onConnectionLost)
        .then(()=>{
          
            this.connections.set(userID, remoteStreamController);
            
            //Start with remote user muted
            remoteStreamController.setVolume(0);

            //Notify all listeners of a new connection
            this.listeners.forEach((listener)=>{                
                listener.onRemoteStreamConnected(remoteStreamController);                
            });
        

        })
        .fail((ex)=>{           
            
        });
        
        
    }


    private onConnectionLost = (remoteStreamController : RemoteStreamController) =>{
        //Remove the connection and notify all of our listeners it is gone
        if(this.connections.has(remoteStreamController.userID)){
            this.connections.delete(remoteStreamController.userID);
            this.listeners.forEach((listener)=>{
                listener.onRemoteStreamDisconnected(remoteStreamController);
            });                   
        }
    }


 


}