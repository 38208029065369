import React, { Component } from 'react';
import styles from './MicAndCameraSettings.module.css';
import { FormGroup, Select, Button, MenuItem } from '@material-ui/core';
import { faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {liveswitchController}  from '../../liveSwitch/LiveSwitchController';
import {DeviceCounts, MediaDevice}  from '../../liveSwitch/LocalMediaController';
import {MessageBus} from '../../utilities/MessageBus';
import { Console } from 'console';

let DetectRTC = require('detectrtc');

interface Props {
  onMicAndCameraComplete: () => void;
}

interface State {
  isWebsiteHasMicrophonePermissions : boolean
  isWebsiteHasWebcamPermissions : boolean,  
  audioInputDevices : any | null,
  videoInputDevices : any | null,
  useMic: boolean,
  useCamera: boolean,
  deviceError: boolean,
  deviceErrorMsg : string,
  currentMic : MediaDevice | null | undefined,
  currentCamera : MediaDevice | null | undefined,
  audioLevel : number,
  haveDeviceList : boolean,
  deviceErrorStore: string[]
}

export default class MicAndCameraSettings extends Component<Props, State> {

  state = {
    isWebsiteHasMicrophonePermissions : false,    
    isWebsiteHasWebcamPermissions : false,    
    audioInputDevices : null,
    videoInputDevices : null,
    useMic: false,
    useCamera: false,
    deviceError: false,
    deviceErrorMsg : "",
    currentMic : null,
    currentCamera : null,
    audioLevel : 0,
    haveDeviceList : false,
    deviceErrorStore: []
  }

  localMediaChangeInProgress : boolean = false;
  initialLoadComplete = false;

  componentDidMount = () =>{

     //Register this as a listener for mic events
     liveswitchController.localMediaController.AddAudioListener(this.onAudioLevel);

    DetectRTC.load( () => {

      this.setState({
        audioInputDevices: DetectRTC.audioInputDevices,
        videoInputDevices: DetectRTC.videoInputDevices
      });

      if(DetectRTC.hasMicrophone || DetectRTC.hasWebcam){
        
        this.startDevices(DetectRTC.hasMicrophone, DetectRTC.hasWebcam);

      } else {

        if(!DetectRTC.isWebsiteHasMicrophonePermissions && !DetectRTC.isWebsiteHasWebcamPermissions){
          this.setState({deviceError: true, deviceErrorStore: ["Camera or microphone permissions have not been granted"], useMic: false, useCamera: false});
        } else if(!DetectRTC.isWebsiteHasMicrophonePermissions){
            this.setState({deviceError: true, deviceErrorStore: ["Microphone permissions have not been granted"], useMic: false, useCamera: false});
        }else if(!DetectRTC.isWebsiteHasWebcamPermissions){
            this.setState({deviceError: true, deviceErrorStore: ["Camera permissions have not been granted"], useMic: false, useCamera: false});          
        } else {
          this.setState({deviceError: true, deviceErrorStore: ["No camera or microphone detected"], useMic: false, useCamera: false});
        }
      }
    });
  }

  componentWillUnmount = () => {
      liveswitchController.localMediaController.RemoveAudioListener(this.onAudioLevel);
  }

  handleNextButtonClick = () => {
    if(this.localMediaChangeInProgress || !this.initialLoadComplete) return;
    this.props.onMicAndCameraComplete();
  }

  toggleMuteMic = () => {

    if(this.localMediaChangeInProgress) return;

    this.localMediaChangeInProgress = true;    
    let useMic = !this.state.useMic;
    let useCamera = this.state.useCamera;

    if(useMic){
      this.startDevices(useMic, useCamera);
    } else {

      if(useCamera){
        liveswitchController.localMediaController.stopAudio().then(()=>{
          this.setState({deviceError: false, deviceErrorStore: [], useMic: useMic, useCamera: useCamera});
          this.localMediaChangeInProgress = false;
          MessageBus.Raise("onUseCameraChanged", {useCamera: useCamera, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});
        }).catch((ex)=>
        {
          this.setState({deviceError: true, deviceErrorStore: ["Error stopping Microphone"], useMic: false, useCamera: false}); //should this always be false?
          console.log("Error stopping audio");
          console.log(ex);
          this.localMediaChangeInProgress = false;
        });

      } else {

        liveswitchController.localMediaController.stopAudioAndVideo().then(()=>{
          this.setState({deviceError: false, deviceErrorStore: [], useMic: useMic, useCamera: useCamera});
          this.localMediaChangeInProgress = false;
        }).catch((ex)=>
        {
          this.setState({deviceError: true, deviceErrorStore: ["Error stopping Microphone"], useMic: false, useCamera: false}); //should this always be false?
          console.log("Error stopping audio");
          console.log(ex);
          this.localMediaChangeInProgress = false;
        });

      }
    
    }

  }

  handleMuteCameraClick = () => {

    if(this.localMediaChangeInProgress) return;

    this.localMediaChangeInProgress = true;    
    let useMic = this.state.useMic;
    let useCamera = !this.state.useCamera;


    if(useCamera){
      this.startDevices(useMic, useCamera);
    } else {

      MessageBus.Raise("onUseCameraChanged", {useCamera: useCamera, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});

      if(useMic){
        liveswitchController.localMediaController.stopVideo().then(()=>{
          this.setState({deviceError: false, deviceErrorStore: [], useMic: useMic, useCamera: useCamera});
          this.localMediaChangeInProgress = false;
          
        }).catch( (ex)=> {
          this.setState({deviceError: true, deviceErrorStore: ["Error stopping Camera"], useMic: false, useCamera: false}); //should this always be false?
          console.log("Error stopping audio");
          console.log(ex);
          this.localMediaChangeInProgress = false;
        });

      } else {

        liveswitchController.localMediaController.stopAudioAndVideo().then(()=>{
          this.setState({deviceError: false, deviceErrorStore: [], useMic: useMic, useCamera: useCamera});
          this.localMediaChangeInProgress = false;
        }).catch((ex)=>
        {
          this.setState({deviceError: true, deviceErrorStore: ["Error stopping Camera"], useMic: false, useCamera: false}); //should this always be false?
          console.log("Error stopping audio");
          console.log(ex);
          this.localMediaChangeInProgress = false;
        });

      }
    
    }


  }

  startDevices = (useMic: boolean, useCamera: boolean) => {

    if(useMic || useCamera){

      //Pick the appropriate device start option
      let mediaLoadFunction : () => Promise<boolean>;
      if(useMic && useCamera){
        mediaLoadFunction = liveswitchController.localMediaController.startAudioAndVideo;
      } else if(useMic){
        mediaLoadFunction = liveswitchController.localMediaController.startAudio;
      } else if(useCamera){
        mediaLoadFunction = liveswitchController.localMediaController.startVideo;
      }


      //Start the devices
      mediaLoadFunction().then(()=>{ 
        this.setState( {useMic: useMic, useCamera: useCamera, deviceError:false});
        this.localMediaChangeInProgress = false;

        if(useMic){
          this.setState({currentMic: liveswitchController.localMediaController.getAudioDevice()});
        } else {
          //this.setState({currentMic: null});
        }

        if(useCamera){
          this.setState({currentCamera: liveswitchController.localMediaController.getVideoDevice()});
          MessageBus.Raise("onUseCameraChanged", {useCamera: useCamera, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});
        } else {
          //this.setState({currentCamera: null});
        }

        //Check if we need devices still        
        this.fetchDevices();
        

      }).catch((ex)=>{

        console.log("Failed starting device", useMic, useCamera);
        console.log(ex);
        this.localMediaChangeInProgress = false;

        let _errorMsg = [];

        if(useMic && useCamera){
          _errorMsg.push("Failed to start camera or microphone");
        } else if(useMic){
          _errorMsg.push("Failed to start microphone");
        } else if(useCamera){
          _errorMsg.push("Failed to start camera");
        }

        if(!DetectRTC.isWebsiteHasMicrophonePermissions || DetectRTC.isWebsiteHasWebcamPermissions){
          _errorMsg.push("Browser permissions have not been granted");
        };
        
        this.setState( {deviceError:true, deviceErrorStore: _errorMsg, useMic: false, useCamera: false});
        
        this.fetchDevices();

        
      });


    } else {

      this.setState({
        useMic: false,
        useCamera: false,
        audioLevel: 0,
      });

      liveswitchController.localMediaController.stopAudioAndVideo().then(()=>{      
        this.setState( {deviceError:false});
        this.localMediaChangeInProgress = false;
      }).catch((ex)=>{
        console.log("Failed stopping microphone");
        this.setState( {useMic: false, useCamera: false, deviceError:true, deviceErrorStore: ["Failed to stop camera or microphone"]});
        this.localMediaChangeInProgress = false;
      });     
    }
  }


  fetchDevices = () => {

    if(!DetectRTC.MediaDevices || (DetectRTC.MediaDevices[0] && DetectRTC.MediaDevices[0].isCustomLabel) ){
      DetectRTC.load(() => {

        this.initialLoadComplete = true;

        let _errorMsg =[];
        let hasError = false;
        if(!DetectRTC.isWebsiteHasMicrophonePermissions && !DetectRTC.isWebsiteHasWebcamPermissions){
          _errorMsg.push("Camera or microphone permissions have not been granted");
          hasError = true;
        } else if(!DetectRTC.isWebsiteHasMicrophonePermissions){
          _errorMsg.push("Microphone permissions have not been granted");
          hasError = true;
        }else if(!DetectRTC.isWebsiteHasWebcamPermissions){
          _errorMsg.push("Camera permissions have not been granted");    
          hasError = true;
        }


        if(!DetectRTC.hasMicrophone && !DetectRTC.hasWebcam){
          if(_errorMsg.length > 0){
            _errorMsg.push(" ");
            hasError = true;
          }
          _errorMsg.push("Could not locate camera or microphone");
        }

        this.setState({
          deviceError : hasError,
          deviceErrorStore : _errorMsg,
          audioInputDevices : DetectRTC.audioInputDevices,
          videoInputDevices : DetectRTC.videoInputDevices
        });
  
      });
    } else {
      this.initialLoadComplete = true;
    }
  }


  onAudioDeviceChange = (event : any) => {    

    if(this.localMediaChangeInProgress) return;
    this.localMediaChangeInProgress = true;

    if(this.state.audioInputDevices && this.state.audioInputDevices.length > 0){

      let deviceID = event.target.value;
      let deviceName = "";

      let i=0;
      for(i=0; i< this.state.audioInputDevices.length; ++i){
          if(this.state.audioInputDevices[i].deviceId == deviceID){
              deviceName = this.state.audioInputDevices[i].label;              

              liveswitchController.localMediaController.changeAudioSource(deviceID, deviceName).then(()=>{
              
                this.setState({currentMic: this.state.audioInputDevices[i], deviceError: false});
                this.localMediaChangeInProgress = false;

              }).catch((ex)=>{
                console.log("Failed to change microphone");
                console.log(ex);
                this.setState({deviceError: true, deviceErrorStore: ["Failed to change microphone"]});
                this.localMediaChangeInProgress = false;
              });
              
              break;
          }
      }
    }

  }


  onCameraDeviceChange = (event : any) => {

    if(this.localMediaChangeInProgress) return;
    this.localMediaChangeInProgress = true;

    if(this.state.videoInputDevices && this.state.videoInputDevices.length > 0){

      let deviceID = event.target.value;
      let deviceName = "";

      let i=0;
      for(i=0; i< this.state.videoInputDevices.length; ++i){
          if(this.state.videoInputDevices[i].deviceId == deviceID){
              deviceName = this.state.videoInputDevices[i].label;

              liveswitchController.localMediaController.changeVideoSource(deviceID, deviceName).then(()=>{
              
                this.setState({currentCamera: this.state.videoInputDevices[i], deviceError: false});
                this.localMediaChangeInProgress = false;

              }).catch((ex)=>{
                console.log("Failed to change camera devices");
                console.log(ex);
                this.setState({deviceError: true, deviceErrorStore: ["Failed to change camera"]});
                this.localMediaChangeInProgress = false;
              });
              
              break;
          }
      }
    }

  }


  onAudioLevel = (level : number) => {

    this.setState({
      audioLevel : level
    });

  }

  render() {

    //Microphone
    let currentAudioDeviceID : string = "";
    let audioInputMenuItems : JSX.Element[] = [];

    //Get the current mic
    if(this.state && this.state.currentMic != undefined){
        currentAudioDeviceID = this.state.currentMic.id;
    }; 

    //Populate the list of mic dropdown        
    if(this.state.audioInputDevices && this.state.audioInputDevices.length > 0){
      audioInputMenuItems = this.state.audioInputDevices.map( (mediaDevice : MediaDeviceInfo)=>{
        return <MenuItem key={mediaDevice.deviceId} value={mediaDevice.deviceId}>{mediaDevice.label}</MenuItem>;
      });

    } else {
      currentAudioDeviceID = "Unknown";
      audioInputMenuItems.push(<MenuItem key={"Unknown"} value={"Unknown"}>{"No microphones found"}</MenuItem>);
    }


    //Camera
    let currentVideoDeviceID : string = "";
    let cameraInputMenuItems : JSX.Element[] = [];

    

    //Get the current mic
    if(this.state && this.state.currentCamera != undefined){
      currentVideoDeviceID = this.state.currentCamera.id;
    }; 

    //Populate the list of camera dropdown        
    if(this.state.videoInputDevices && this.state.videoInputDevices.length > 0){
      cameraInputMenuItems = this.state.videoInputDevices.map( (mediaDevice : MediaDeviceInfo)=>{
        return <MenuItem key={mediaDevice.deviceId} value={mediaDevice.deviceId}>{mediaDevice.label}</MenuItem>;
      });

    } else {
      currentVideoDeviceID = "Unknown";
      cameraInputMenuItems.push(<MenuItem key={"Unknown"} value={"Unknown"}>{"No cameras found"}</MenuItem>);
    }




    let buttonClassesMicrophone;

    //Put pseudoelement (red X) over button if mic is muted
    if (this.state.useMic === true) {
      buttonClassesMicrophone = `${styles.microphoneToggle}`;
    } else {
      buttonClassesMicrophone = `${styles.microphoneToggle} ${styles.microphoneToggleMute}`
    }

    let buttonClassesCamera;
    //show pseudoelement (red X) if camera is muted
    if (this.state.useCamera === true) {
      buttonClassesCamera = `${styles.cameraToggle}`;
    } else {
      buttonClassesCamera = `${styles.cameraToggle} ${styles.cameraToggleMute}`
    }

    //Set heights on audio reactives
    let container1Style = {
      height: 10 + this.state.audioLevel * 140 + 'px'
    }

    let container2Style = {
      height: 10 + this.state.audioLevel * 190 + 'px'
    }

    let container3Style = {
      height: 10 + this.state.audioLevel * 140 + 'px'
    }

    return (
      <div className={styles.micAndCameraSettingsHolder}>
        <div className={styles.microphoneHolder}>

          <div className={styles.deviceErrorMsgHolder}>
            {this.state.deviceError && 
              this.state.deviceErrorStore.map(error => {
                return (
                  <p className={styles.deviceErrorMsg}>{error}</p>
                )
              })
            }
          </div>

          <div className={styles.microphoneTestAndHeader}>
            <h1 className={styles.microphoneHeader}>Test your mic by talking</h1>
            <div className={styles.microphoneTestWrapper}>
                <div className={styles.microphoneTestHolder}>
                  <div className={styles.micBarOne} style={container1Style}></div>
                  <div className={styles.micBarOneDown} style={container1Style}></div>

                  <div className={styles.micBarTwo} style={container2Style}></div>
                  <div className={styles.micBarTwoDown} style={container2Style}></div>

                  <div className={styles.micBarThree} style={container3Style}></div>
                  <div className={styles.micBarThreeDown} style={container3Style}></div>
                </div>
              </div>
          </div>

          <div className={styles.microphoneSelectHolder}>
            <h1 className={styles.microphoneSelectHeader}>Microphone</h1>
            <FormGroup key = {"FormGroup"} row className={styles.formGroupAudioSelect}>
              <Select  
                  labelId="demo-simple-select-label"  
                  id="demo-simple-select"  
                   value={currentAudioDeviceID}  
                   onChange={this.onAudioDeviceChange}  
                  MenuProps={{ className: 'microphoneSelectMenu' }}
                >          
                  {audioInputMenuItems}
                </Select>                   
            </FormGroup>

            <button className={buttonClassesMicrophone} id="microphoneToggle" onClick={this.toggleMuteMic}>
            </button>
          </div>
        </div>
        <div className={styles.cameraHolder}>
          <h1 className={styles.cameraSettingsHeader}>Choose your camera</h1>

        
          <div className={styles.cameraSelectHolder}>
            <h2 className={styles.cameraSelectHeader}>Camera</h2>

            <FormGroup  key = {"FormGroup"} row className={styles.formGroupCameraSelect}>
                <Select 
                  labelId="demo-simple-select-label"  
                  id="demo-simple-select" 
                   value={currentVideoDeviceID}
                   onChange={this.onCameraDeviceChange}
                  MenuProps={{ className: 'cameraSelectMenu' }}
                >                                                   
                    {cameraInputMenuItems}
                </Select>                    
            </FormGroup>

            <button className={buttonClassesCamera} id="cameraToggle" onClick={this.handleMuteCameraClick}>
            </button>

            <Button className={styles.micAndCameraNextButton} variant="contained" color="primary" onClick={this.handleNextButtonClick}>Next</Button>
          </div>
        </div>
      </div>
    )
  }
}
