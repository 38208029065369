import * as BABYLON from 'babylonjs';
import { AvatarData } from '../avatars/AvatarData';
import 'babylonjs-loaders';
import { BabylonFileLoaderConfiguration, Scene, PBRMaterial, VertexBuffer, Mesh, Vector3, Material, SimplexPerlin3DBlock, Texture, VideoTexture } from 'babylonjs';
import SocketIOController, { SocketIOSubscriber } from '../scene/SocketIOController';
import { RoomCodeData, RoomCodeStruct} from '../utilities/RoomCodeData';
import { MessageBus } from '../utilities/MessageBus';
import { RemoteScreenShareController } from '../liveSwitch/RemoteScreenShareController';
import Hls from 'hls.js';
/* import Hls from 'hls.js'; */

export class JumbotronController implements SocketIOSubscriber{
    private socketIOController : SocketIOController;
    private targetMaterial : PBRMaterial;

    private slides : Array<[string, string]> = [];

    private scene : Scene;

    private jumboTronTexture : BABYLON.Texture;
    private videoTexture : BABYLON.VideoTexture;

    elapsedTimeSinceSlideChange : number = 0;

    constructor(private engine: BABYLON.Engine, socketIOController: SocketIOController, targetMaterial : BABYLON.PBRMaterial, scene : Scene, private roomCodeData : RoomCodeStruct)
    {
        this.socketIOController = socketIOController;
        this.socketIOController.AddListener(this);
        this.targetMaterial = targetMaterial;
        this.scene = scene;
     
        if(roomCodeData && roomCodeData.slides && roomCodeData.slides.length > 0){   
            for(let index in roomCodeData.slides){
                let slideData =  roomCodeData.slides[index];
                console.log(slideData);
                this.slides.push(slideData);                 
            }
                      

        } else {
            this.slides.push(["video", "1726134645-grad.mp4"]);
            this.slides.push(["image", "slide-01.png"]);
            this.slides.push(["image", "slide-02.png"]);
        }
        

        //Jumbotron slide switching
        this.scene.registerBeforeRender(() => {
            this.elapsedTimeSinceSlideChange += this.engine.getDeltaTime();
        });


        this.scene.onKeyboardObservable.add((kbInfo) => {
                                
            switch(kbInfo.type){
                case BABYLON.KeyboardEventTypes.KEYDOWN:
                    //throttle sending too many requests
                    if(this.elapsedTimeSinceSlideChange > 250){
                        

                        let slideCount = 3;
                        if(roomCodeData && roomCodeData.slides && roomCodeData.slides.length > 0){
                            slideCount = roomCodeData.slides.length
                        }

                        let slideNumber = Number.parseInt(kbInfo.event.key);
                        if(slideNumber >= 0 && slideNumber < slideCount){
                            this.elapsedTimeSinceSlideChange = 0;
                            this.socketIOController.SetServerVariable("jumboTronMsg", {slideNumber:slideNumber});
                        }
                    }


                    if(kbInfo.event.key == "v"){
                        this.startHLSShare();
                    }

                break;    
            }
        });
       

        //this.SetSlide(0);

        MessageBus.AddListener("StartScreenShare", this.onStartScreenShare);


    }

    
    startHLSShare = () => {
        console.log("HLS");
        let video = document.createElement('video');
        var videoSrc = 'https://parlor.works/hls/aaa.m3u8';
        if (Hls.isSupported()) {
            var hls = new Hls();

            //Hls.DefaultConfig.set
           
            hls.attachMedia(video);

            hls.on(Hls.Events.ERROR, function (event, data) {
                var errorType = data.type;
                var errorDetails = data.details;
                var errorFatal = data.fatal;
            
                console.log(event);
                console.log(data);
                switch(data.details) {
                  case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                    // ....
                    break;
                  default:
                    break;
                }
              });
            

            hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                console.log("video and hls.js are now bound together !");

                hls.loadSource(videoSrc);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    video.play();
                    //hls.liveSyncPosition


                    hls.config.liveDurationInfinity = true;
                    hls.config.liveBackBufferLength = 0;


                   
                    //video.currentTime = video.duration - 1;
                    this.videoTexture = new BABYLON.VideoTexture("screnShareVideoTexture", video, this.scene, true, false);
                    this.targetMaterial.albedoTexture = this.videoTexture;  
                });
            });
           
        }
    }
    

    startScreenShare = (htmlVideoElement : HTMLVideoElement) => {
        this.videoTexture = new BABYLON.VideoTexture("screnShareVideoTexture", htmlVideoElement, this.scene, true, false);
        this.targetMaterial.albedoTexture = this.videoTexture;  
    }

    onStartScreenShare = (remoteScreenShareController: RemoteScreenShareController) => {
        this.startScreenShare(remoteScreenShareController.htmlVideoElement);        
    }



    onPlayerConnected =  () => {};
    onRemotePlayerConnected = (playerData: AvatarData) => {};
    onRemotePlayerDisconnected = (playerID: AvatarData) => {};
    onMessage = (messageName: string, message: any) => {};
    
    onGlobalMessage = (messageName: string, message: any) => {     
        /*   
        if(messageName == "jumboTronMsg"){
            this.SetSlide(message.slideNumber);
        }
        */
    }

    onVariableUpdate = (variableName: string, newValue: any) => {       
      
        /*
        if(variableName == "jumboTronMsg"){
            this.SetSlide(newValue.slideNumber);
        }
        */

    }
    
    onPlayerPositionsUpdate = (playerData: any) => {};
    onPlayerDataUpdate = (playerData: AvatarData) => {};

    public SetSlide(slideNumber : number){
      
        if(slideNumber >= 0 && this.slides.length > slideNumber){          
           
            if(this.slides[slideNumber][0] == "image"){                

                this.jumboTronTexture = new BABYLON.Texture("./assets/textures/Jumbotron/" + this.slides[slideNumber][1], this.scene, false, true, 3, ()=> {

                    //Get reference to old material
                    let oldTexture = this.targetMaterial.albedoTexture;

                    //Change to the new texture
                    this.targetMaterial.albedoTexture = this.jumboTronTexture;

                    //Get rid of the old texture
                    if(oldTexture){
                        oldTexture.dispose();
                    }

                  
                    
                });
            }
            else if(this.slides[slideNumber][0] == "video"){

                let videoTextureSettings : BABYLON.VideoTextureSettings = {
                    loop: true,
                    autoPlay: true,
                    autoUpdateTexture: true                    
                };

                this.videoTexture = new BABYLON.VideoTexture(this.slides[slideNumber][1] ,"./assets/videos/Jumbotron/" + this.slides[slideNumber][1], this.scene, true, false, BABYLON.Texture.TRILINEAR_SAMPLINGMODE, videoTextureSettings);
                this.videoTexture.video.preload = "auto";
                //newVideoTexture.video.muted = true;
                
                //Handle the startup case and go with the video texture right away if nothing else exists
                //if(!this.videoTexture && !this.jumboTronTexture){
                //    this.targetMaterial.albedoTexture = newVideoTexture;
                // }

                //What for the promise to be finished to play
                BABYLON.VideoTexture.WhenAllReady([this.videoTexture], ()=>{
                    console.log("Ready to play video");
                    this.targetMaterial.albedoTexture = this.videoTexture;
                    this.videoTexture.video.play();

                    if(this.jumboTronTexture){
                        this.jumboTronTexture.dispose();
                    }

                    

                   
                });

                //Monitor the onplay event to add texture to the material
                
                this.videoTexture.video.onplay =(()=>{    
                    this.targetMaterial.albedoTexture = this.videoTexture;
                });
                

                //Listen for errors
                this.videoTexture.video.onerror = ((event)=>{
                    console.log("Video error");
                    console.log(event);
                });


                //Handle stubborn mobile / browsers
                this.videoTexture.onUserActionRequestedObservable.add(() => {                    
                    this.scene.onPointerDown = () => { 
                        console.log(" Bump Browser to play video");
                        this.videoTexture.video.play();
                        
                    }
                });
                
                
            }
        }
    }

    public dispose(){
        //TODO TODO any othe materials/texure to clean up ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TODO TODO TODO
    }

  

}